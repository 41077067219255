import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
export const _frontmatter = {
  "title": "Uses"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Uses`}</h1>
    <p>{`Blocks depends heavily on some awesome tools, it wouldn't be possible
without them, so let's give some credit.`}</p>
    <h2>{`Editor`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`React`}</strong>{`: Rendering layer`}</li>
      <li parentName="ul"><strong parentName="li">{`Babel`}</strong>{`: Parsing, transpiling, transforming, and compiling JavaScript`}</li>
      <li parentName="ul"><strong parentName="li">{`Theme UI`}</strong>{`: `}<inlineCode parentName="li">{`sx`}</inlineCode>{` prop and its components`}</li>
      <li parentName="ul"><strong parentName="li">{`Emotion`}</strong>{`: CSS-in-JS implementation for Theme UI`}</li>
      <li parentName="ul"><strong parentName="li">{`React Beautiful DND`}</strong>{`: Dragging and dropping components`}</li>
      <li parentName="ul"><strong parentName="li">{`Reach UI`}</strong>{`: Accessible component primitives for tabs`}</li>
      <li parentName="ul"><strong parentName="li">{`Feather Icons`}</strong>{`: Says it on the tin`}</li>
      <li parentName="ul"><strong parentName="li">{`Jest`}</strong>{`: Testing (hopefully by now tests have been written)`}</li>
    </ul>
    <h2>{`Website`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Gatsby`}</strong>{`: For compiling the site and community plugins`}</li>
      <li parentName="ul"><strong parentName="li">{`MDX`}</strong>{`: JSX in Markdown for docs pages`}</li>
    </ul>
    <h2>{`Misc`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Prettier, Husky, Lint Staged`}</strong>{`: Code formatting`}</li>
      <li parentName="ul"><strong parentName="li">{`Preconstruct, manypkg, changesets`}</strong>{`: Monorepo tooling & package versioning`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      